import React from 'react'

import Layout from '../../components/layout'
import ServicesPage from '../../components/PageComponents/Services/myofascialRelease/ServicesPage'
import SEO from "../../components/seo";


const Services = () => (
    <Layout>    
    
            <SEO title="Myofascial Release" />

        <ServicesPage />

    </Layout>
)

export default Services